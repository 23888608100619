import { render, staticRenderFns } from "./Error404.vue?vue&type=template&id=23c85e47&scoped=true"
import script from "./Error404.vue?vue&type=script&lang=js"
export * from "./Error404.vue?vue&type=script&lang=js"
import style0 from "./Error404.vue?vue&type=style&index=0&id=23c85e47&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23c85e47",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CaButton: require('/app/node_modules/@petlove/caramelo-vue-components/src/Button/index.vue').default})
