import AuthService from '../services/auth';

import { OwnPlansPartners } from '@/models/OwnPlansPartners';
import { PurchaseSource } from '@/models/PurchaseSource';
import bannerService from '@/services/bannerService';
import BundleHandler from '@/services/discountClubBundle';
import plansService from '@/services/plansService';
import whitelabelService from '@/services/whitelabelService';
import { filterAndConvertToString } from '@/utils/navigation';

const authService = new AuthService();

export const state = () => ({
  parceiro: {
    status: 0,
    utm: '',
    banner_img: '',
    banner_text: '',
    banner_text_size: '',
    banner_padding: '',
    color_background: '',
    color_text: '',
    discount_percentage: 0,
    cnpj: '',
    social_name: '',
    fantasy_name: '',
    privacy_policy: '',
    telephones: {
      primary: '',
      secundary: ''
    },
    model: null,
    status_logo_porto: true,
    status_logo_partner: false,
    isItau: false
  },
  user: {
    isLoadingData: true,
    pets: [],
    coupon: null,
    email: null,
    isIndication: null,
    customerData: {},
    selectedPlanAtHomeId: null,
    indication: null
  },
  source: '',
  pass: null,
  partnerCode: null,
  hasCustomPlan: false,
  promoCode: null,
  promoDetails: {
    coupon_enabled: true
  },
  youtubeScriptAdded: false,
  youtubeApiReady: false,
  plans: [],
  isPlansLoading: false,
  guestRegion: '',
  regions: [],
  regionByRoute: {},
  isSetAlertCookie: false,
  callbackStatus: false,
  trackWatchSetted: false,
  utm_campaign: '',
  campaign: {
    img: {
      mobile: '',
      desktop: '',
      fullHd: ''
    },
    video: {
      link: '',
      thumb: ''
    },
    bgColor: '',
    btnBgColor: '',
    btnColor: '',
    alt: '',
    start: '',
    end: ''
  },
  requestBenefitsLP: {},
  receiveCall: {},
  loadDY: undefined,
  isDiscountClub: false,
  memberFromBroker: false,
  showBundleAddOn: undefined,
  acceptTermsOfUse: false,
  checkoutDefault: false,
  userType: '',
  partnerWhitelabelConfig: {
    hideInsideSalesPhone: false,
    hidePartnerMenuOptions: false,
    hideWhatsappButton: false
  },
  variantTransparencyStep: undefined,
  discountMorePets: undefined,
  homeFaqs: undefined,
  variantSelectorsPlans: undefined,
  isPromotionRegion: false
});

export const getters = {
  getUser: (state) => state.user,
  customerData: (state) => state.user?.customerData,
  getCustomerData: (state) => state.user?.customerData,
  getSelectedPlanAtHomeId: (state) => state.user?.selectedPlanAtHomeId,
  getPets: (state) => state.user.pets,
  getisLoadingUserData: (state) => state.user.isLoadingData,
  getParceiro: (state) => state.parceiro,
  getPartnerCode: (state) => state.partnerCode,
  hasCustomPlan: (state) => state.hasCustomPlan,
  getPass: (state) => state.pass,
  getIsItau: (state) => state.parceiro?.isItau,
  utmCampaign: (state) => state.utm_campaign,
  getPet: (state) => (index) => state.user.pets[index] || false,
  subTotalPets: (state) => () =>
    state.user.pets.reduce((acc, curr) => {
      acc += curr.basePrice;
      return acc;
    }, 0),
  getSource: (state) => state.source,
  getGuestRegion: (state) => state.guestRegion,
  getRegions: (state) => state.regions,
  getPlans: (state) => state.plans,
  getIsPlansLoading: (state) => state.isPlansLoading,
  getPromoCode: (state) => state.promoCode,
  getPromoDetails: (state) => state.promoDetails,
  getCallbackStatus: (state) => state.callbackStatus,
  getAlertCookieStatus: (state) => state.isSetAlertCookie,
  getCampaign: (state) => state.campaign,
  getLogoPorto: (state) => state.logoPorto,
  getLoadDY: (state) => state.loadDY,
  getIsDiscountClub: (state) => state.isDiscountClub,
  getMemberFromBroker: (state) => state.memberFromBroker,
  getShowBundleAddOn: (state) => state.showBundleAddOn,
  getAcceptTermsOfUse: (state) => state.acceptTermsOfUse,
  getCheckoutDefault: (state) => state.checkoutDefault,
  getUserType: (state) => state.userType,
  getVariantTransparencyStep: (state) => state.variantTransparencyStep,
  getPartnerWhitelabelConfig: (state) => state.partnerWhitelabelConfig,
  getDiscountMorePets: (state) => state.discountMorePets,
  getHomeFaqs: (state) => state.homeFaqs,
  getVariantSelectorsPlans: (state) => state.variantSelectorsPlans,
  getIsPromotionRegion: (state) => state.isPromotionRegion
};
export const mutations = {
  set_partner(state, value) {
    state.parceiro = value;
  },
  set_partnerCode(state, value) {
    state.partnerCode = filterAndConvertToString(value);
  },
  set_pass(state, value) {
    state.pass = value;
  },
  set_utm_campaign(state, value) {
    state.utm_campaign = value;
  },
  set_user(state, user) {
    state.user = user;
  },
  set_customerData(state, customerData) {
    state.user.customerData = customerData;
  },
  set_pets(state, pets) {
    state.user.pets = pets;
  },
  set_user_attribute(state, payload) {
    state.user[payload.key] = payload.value;
  },
  set_user_attribute_customer_data(state, payload) {
    state.user.customerData[payload.key] = payload.value;
  },
  add_pet(state, pet) {
    state.user.pets.push(pet);
  },
  add_indication(state, payload) {
    state.user.indication = payload;
  },
  set_user_isloadingdata(state, value) {
    state.user.isLoadingData = Boolean(value);
  },
  add_plans(state, payload) {
    state.plans = payload;
  },
  update_plans_loading(state, payload) {
    state.isPlansLoading = Boolean(payload);
  },
  set_has_custom_plan(state, payload) {
    state.hasCustomPlan = Boolean(payload);
  },
  remove_pet(state, index) {
    state.user.pets.splice(index, 1);
  },
  update_pet(state, payload) {
    state.user.pets.splice(payload.index, 1, payload.pet);
  },
  set_youtubeScriptAdded(state, value) {
    state.youtubeScriptAdded = value;
  },
  set_youtubeApiReady(state, value) {
    state.youtubeApiReady = value;
  },
  set_source(state, value) {
    state.source = value;
  },
  set_guestRegion(state, value) {
    state.guestRegion = value;
    BundleHandler.handleBundleVarations(state);
  },
  set_regionByRoute(state, value) {
    state.regionByRoute = value;
  },
  unset_regionByRoute(state) {
    state.regionByRoute = {};
  },
  set_promoCode(state, value) {
    state.promoCode = value;
  },
  set_promoDetails(state, value) {
    state.promoDetails = value;
  },
  set_regions(state, value) {
    state.regions = value;
  },
  set_isSetAlertCookie(state, value) {
    state.isSetAlertCookie = value;
  },
  set_callbackStatus(state, value) {
    state.callbackStatus = value;
  },
  set_trackWatchSetted(state, setted) {
    state.trackWatchSetted = setted;
  },
  set_campaign(state, campaign) {
    state.campaign = campaign;
  },
  set_isLogoPorto(state, value) {
    state.parceiro.status_logo_porto = value;
  },
  request_benefits(state, value) {
    state.requestBenefitsLP = value;
  },
  clear_requestBenefitsLP(state) {
    state.requestBenefitsLP = {};
  },
  receive_call(state, value) {
    state.receiveCall = value;
  },
  clear_requestReceiveCall(state) {
    state.receiveCall = {};
  },
  set_loadDY(state, value) {
    state.loadDY = value;
  },
  set_isDiscountClub(state, value) {
    state.isDiscountClub = value;
  },
  set_memberFromBroker(state, value) {
    state.memberFromBroker = value;
  },
  set_showBundleAddOn(state, value) {
    state.showBundleAddOn = value;
  },
  set_acceptTermsOfUse(state, value) {
    state.acceptTermsOfUse = value;
  },
  set_checkoutDefault(state, value) {
    state.checkoutDefault = value;
  },
  set_userType(state, value) {
    state.userType = value;
  },
  set_partnerWhitelabelConfig(state, value) {
    state.partnerWhitelabelConfig.hideInsideSalesPhone = Boolean(
      value.hideInsideSalesPhone
    );
    state.partnerWhitelabelConfig.hidePartnerMenuOptions = Boolean(
      value.hidePartnerMenuOptions
    );
    state.partnerWhitelabelConfig.hideWhatsappButton = Boolean(
      value.hideWhatsappButton
    );
  },
  set_variantTransparencyStep(state, value) {
    state.variantTransparencyStep = value;
  },
  set_discountMorePets(state, value) {
    state.discountMorePets = value;
  },
  set_homeFaqs(state, value) {
    state.homeFaqs = value;
  },
  set_variantSelectorsPlans(state, value) {
    state.variantSelectorsPlans = value;
  },
  set_isPromotionRegion(state, value) {
    state.isPromotionRegion = value;
  }
};

export const strict = false;

export const actions = {
  async setPurchaseDataOnCheckout({ getters, commit, dispatch }, query) {
    if (!getters.getPlans.length) {
      await dispatch('loadPlans');
    }

    commit('set_user_isloadingdata', true);
    // >>> SOURCE <<<
    const isQueryPurchase =
      query.source &&
      (query.source === PurchaseSource.BOT ||
        query.source === PurchaseSource.PARTNER_CLIENT);

    commit('set_source', query.source);

    const plans = getters.getPlans || [];

    if (!isQueryPurchase || !plans.length) {
      commit('set_user_isloadingdata', false);
      return Promise.resolve(false);
    }

    // >>> REGION <<<

    if (query.region_id) {
      const regionId = parseInt(query.region_id);
      const regions = getters.getRegions;

      if (regions && regions.length > 0) {
        const regionData = regions.find((region) => region.id === regionId);
        regionData.firstAccess = false;

        dispatch('setGuestRegion', regionData);
      }
    }

    // >>> PETS AND PLANS <<<

    if (query.pets) {
      const pets = query.pets;
      const petsData = [];

      if (pets?.length >= 1) {
        pets.forEach((pet, petIndex) => {
          const petData = {};

          if (pet.name) {
            petData.name = pet.name;
          }

          if (pet.plan_id) {
            const plans = getters.getPlans;

            const plan = plans.find(
              (plan) => plan.id === parseInt(pet.plan_id)
            );

            if (plan) {
              petData.plan_id = plan.id;
              petData.plan_name = plan.name;
              petData.plan = plan.name;
              petData.basePrice = plan.price;
              petData.price = plan.price;
            }

            petData.petCount = petIndex;
          }
          petsData.push(petData);
        });

        commit('set_pets', petsData);
        await dispatch('getValuesCheckout');
      }
    }

    // >>> CUSTOMER <<<

    if (query.customer) {
      const customer = query.customer;
      const customerData = {};

      if (customer.name) {
        customerData.name = customer.name;
      }
      if (customer.email) {
        customerData.email = customer.email;
      }
      if (customer.phone) {
        customerData.ddd = customer.phone.slice(0, 2);
        customerData.telephone = customer.phone.slice(2);
      }
      if (customer.CPF) {
        customerData.cpf = customer.CPF;
      }
      if (customer.address?.zipCode) {
        customerData.zipcode = customer.address.zipCode;
      }
      if (customer.address?.number) {
        customerData.addressNumber = customer.address.number;
      }
      if (customer.address?.complement) {
        customerData.addressComplement = customer.address.complement;
      }
      commit('set_customerData', customerData);
    }

    if (query.discount_club) {
      commit('set_isDiscountClub', true);
    }

    commit('set_user_isloadingdata', false);
    return Promise.resolve({ hasAddressData: query.customer?.address });
  },
  setPartner({ commit }, partner) {
    if (partner.status) {
      const parceiro = {
        status: partner.status,
        utm: partner.utm_slug,
        banner_img: partner.banner_img,
        banner_text: partner.banner_text,
        banner_text_size: partner.banner_text_size,
        banner_padding: partner.banner_padding,
        color_background: partner.color_background,
        color_text: partner.color_text,
        discount_percentage: partner.discount_percentage,
        cnpj: partner.cnpj,
        social_name: partner.social_name,
        fantasy_name: partner.fantasy_name,
        privacy_policy: partner.privacy_policy,
        telephones: partner.telephones,
        status_logo_porto: partner.status_logo_porto,
        status_logo_partner: partner.status_logo_partner
      };

      if (partner.model) {
        parceiro.model = partner.model;
        commit('set_pass', partner.model === 'pass');
      }

      if (partner.utm_slug.includes('itau')) {
        parceiro.isItau = true;
      }

      commit('set_partner', parceiro);
      commit('set_partnerCode', partner.utm_slug);
    } else {
      commit('set_partner', {});
      commit('set_pass', null);
    }
  },
  setUser({ commit }, user) {
    commit('set_user', user);
  },
  async getValuesCheckout(
    { getters, state, commit, rootGetters },
    getOriginalValues
  ) {
    let headers = {};
    const paymentMethod = rootGetters['payment/getPaymentMethod'];
    const discountClub = rootGetters['additionalServices/getDiscountClub'];
    const isDiscountClub = rootGetters.getIsDiscountClub;
    const billedAnnually = getOriginalValues
      ? 0
      : rootGetters['payment/getBilledAnnually'];
    const CardBin = rootGetters['payment/getCardBin'];

    const isItau = getters.getIsItau;

    if (authService.isLogged()) {
      headers = authService.getAuthHeaders();
    }

    const params = {
      plans: state.user.pets.map((pet) => pet.plan_id).join(','),
      petsNames: state.user.pets.map((pet) => pet.name).join(','),
      billedAnnually: isItau ? 0 : billedAnnually, // anual
      email: state.user.email,
      paymentMethod,
      type_purchase_club: state.user.discountClub ? 'addon' : null,
      coupon: state.user.coupon ? state.user.coupon.code : null,
      // TODO Simulate recebe utm_source e o checkout recebe partner
      utm_source: sessionStorage.getItem('utm_source'),
      partner: getters.getPartnerCode,
      card_bin: CardBin,
      addonsConfigIds: isDiscountClub ? [discountClub.id] : []
    };
    if (state.parceiro?.model === 'pass') {
      params.model = state.parceiro.model;
      params.cpf = state.user.customerData.cpf;
    }
    await this.$axios
      .$get('/subscription/simulate', {
        headers,
        params
      })
      .then((simulation) => {
        commit('payment/set_orderValues', simulation, { root: true });
        commit('set_user_attribute', { key: 'pets', value: simulation.pets });
      })
      .catch((error) => {
        console.error(error);
        const isError422 = error?.response?.status === 422;

        if (!isError422) {
          this.$nuxt?.$honeybadger?.notify(
            `Fluxo de simulação de preços dos planos - ${error} - ${new Date()}`,
            {
              tags: ['critical', 'contrate']
            }
          );
        }
        throw error;
      });
  },
  async loadPlans({ state, commit, dispatch }) {
    commit('update_plans_loading', true);

    const promoCode = state.promoCode;
    const guestRegion = state.guestRegion;
    if (!guestRegion) {
      dispatch('loadRegions');
    }

    const urlParams =
      window?.location?.search && new URLSearchParams(window.location.search);

    await Promise.all([
      plansService.plans(
        this,
        guestRegion ? guestRegion.id : null,
        promoCode || null,
        {
          pass: urlParams && urlParams.get('pass'),
          partner:
            state.partnerCode || (urlParams && urlParams.get('utm_source'))
        }
      ),
      plansService.coverageDetails(this)
    ])
      .then((responses) => {
        if (responses[0].status === 203) {
          commit('set_promoCode', null);
          commit('set_promoDetails', {});
          commit('set_isPromotionRegion', false);
        } else if (promoCode) {
          commit('set_isPromotionRegion', true);
        }

        const plans = responses[0].data;

        const hasCustomPlan = plans.some(
          (plan) => plan.is_custom_plan === true
        );
        commit('set_has_custom_plan', hasCustomPlan);

        const coverageDetails = responses[1];

        plans.forEach((plan) => {
          plan.coverage = plan?.coverage?.map((x) => {
            return coverageDetails.find((y) => y.id === x);
          });

          plan.color = !plan.color ? '#7b4eb9' : plan.color;

          if (plan?.baseline?.coverage) {
            plan.baseline.coverage = plan?.baseline?.coverage?.map((x) => {
              return coverageDetails.find((y) => y.id === x);
            });
          }
        });
        commit('add_plans', plans);
      })
      .catch(() => {
        commit('add_plans', []);
      })
      .finally(() => {
        commit('update_plans_loading', false);
      });
  },
  setGuestRegion({ getters, commit }, selectedRegion) {
    const regions = getters.getRegions;
    const selectedRegionIndex = regions.findIndex(
      (region) => region.id === selectedRegion.id
    );

    if (selectedRegionIndex > 0) {
      regions.splice(selectedRegionIndex, 1);
      regions.unshift(selectedRegion);
    }

    commit('set_guestRegion', selectedRegion);
  },
  async loadRegions({ getters, commit, dispatch }) {
    try {
      const areRegionsLoaded =
        getters.getRegions && getters.getRegions.length > 0;

      const regions = areRegionsLoaded
        ? await Promise.resolve(getters.getRegions)
        : await this.$axios.get('/regions');

      let selectedRegion = getters.getGuestRegion
        ? getters.getGuestRegion
        : await dispatch('setGuestRegionToDefault', regions.data);

      if (selectedRegion.id && !selectedRegion?.slug) {
        selectedRegion = regions?.data.find((region) => {
          return region.id === selectedRegion.id;
        });
      }

      const selectedRegionIndex = regions.data.findIndex(
        (region) => region.id === selectedRegion.id
      );
      regions.data.splice(selectedRegionIndex, 1);
      regions.data.unshift(selectedRegion);

      await commit('set_guestRegion', selectedRegion);
      await commit('set_regions', regions.data);

      return regions;
    } catch (error) {
      console.error(error);
      return Promise.reject(error);
    }
  },
  setGuestRegionToDefault({ commit }, regions) {
    const regionDefault = regions.find((region) => {
      return region.is_default === true;
    });

    if (regionDefault) {
      regionDefault.firstAccess = true;
      commit('set_guestRegion', regionDefault);
    }
    return regionDefault;
  },
  setCampaign({ commit }) {
    bannerService.getBanner(this).then((data) => {
      if (data?.campaign) {
        commit('set_campaign', data.campaign);
      }
    });
  },
  setPromotionCode({ commit }, promoCode) {
    commit('set_promoCode', promoCode);
    if (!OwnPlansPartners) {
      commit('set_partnerCode', null);
    }
    commit('set_pass', null);
  },
  setPassCode({ commit }, passCode) {
    commit('set_promoCode', null);
    commit('set_partnerCode', passCode);
    commit('set_pass', true);
  },
  setPartnerCode({ commit }, partnerCode) {
    if (!OwnPlansPartners) {
      commit('set_promoCode', null);
    }
    commit('set_partnerCode', partnerCode);
    commit('set_pass', null);
  },
  async setPartnerConfig({ commit }, partnerCode) {
    if (!partnerCode) return;

    try {
      const response = await whitelabelService.getParnerConfig(
        this,
        filterAndConvertToString(partnerCode)
      );

      const partnerWhitelabelConfig = {
        hideInsideSalesPhone: Boolean(response?.hide_inside_sales_phone),
        hidePartnerMenuOptions: Boolean(response?.hide_partner_menu_options),
        hideWhatsappButton: Boolean(response?.hide_whatsapp_button)
      };

      commit('set_partnerWhitelabelConfig', partnerWhitelabelConfig);
    } catch (error) {
      commit('set_partnerWhitelabelConfig', {
        hideInsideSalesPhone: false,
        hidePartnerMenuOptions: false,
        hideWhatsappButton: false
      });
      console.error('Error fetching partner config:', error);
      return Promise.reject(error);
    }
  },
  clearRequestBenefitsLP({ commit }) {
    commit('clear_requestBenefitsLP');
  },
  clearRequestReceiveCall({ commit }) {
    commit('clear_requestReceiveCall');
  }
};
